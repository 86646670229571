/* eslint-disable @typescript-eslint/no-explicit-any */
import IValidationRule from '../type/IValidationRule';
import Symbols from '../Symbols';
import { action, makeObservable, observable } from 'mobx';

export interface ModelProp {
	name: string;
	displayName: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class AbstractModel<T> {
	public constructor() {
		makeObservable(this);
	}

	public static props: { [k: string]: ModelProp } = {};

	@action
	public static fromJSON<T>(this: new () => AbstractModel<T>, values: Partial<T>): T {
		const model = new this();

		model.forEachProp((prop) => {
			if (Object.getOwnPropertyDescriptor(values, prop)) {
				(model as any)[prop] = (values as any)[prop];
			}
		});

		return model as any;
	}

	@observable
	public validationErrors: Map<string, IValidationRule[]> = observable.map();

	public get validationRules(): IValidationRule[] {
		return (this as any)[Symbols.Validators] as IValidationRule[];
	}

	@action
	public reset() {
		this.forEachProp((key) => ((this as any)[key] = undefined));
	}

	@action
	public set(propertyName: string, propertyValue: any) {
		(this as any)[propertyName] = propertyValue;
	}

	public get(propertyName: string) {
		return (this as any)[propertyName];
	}

	public toJSON(): any {
		const json: any = {
			...this,
		};

		delete json.validationErrors;

		return json;
	}

	@action
	public clearValidationErrors(fieldsToValidate?: string[]) {
		if (fieldsToValidate && fieldsToValidate.length) {
			fieldsToValidate.forEach((field) => {
				if (this.validationErrors.has(field)) {
					this.validationErrors.delete(field);
				}
			});
		} else {
			this.validationErrors.clear();
		}
	}

	@action
	public validate(fieldsToValidate?: string[]): boolean {
		let isValid = true;

		this.clearValidationErrors(fieldsToValidate);

		this.validationRules.forEach((rule) => {
			// Skip this rule if we've defined a whitelist of fields to validate
			if (fieldsToValidate && fieldsToValidate.indexOf(rule.target) < 0) {
				return;
			}

			let val = (this as any)[rule.target];

			if (typeof val != 'boolean' && typeof val != 'number') {
				val = `${val || ''}`;
			}

			const args = [val].concat(rule.args || []);

			if (!rule.callback.apply(this, args)) {
				if (!this.validationErrors.has(rule.target)) {
					this.validationErrors.set(rule.target, []);
				}
				this.validationErrors.get(rule.target)?.push(rule);
				isValid = false;
			}
		});

		return isValid;
	}

	@action
	public async validateAsync(fieldsToValidate?: string[]): Promise<boolean> {
		let isValid = true;

		this.clearValidationErrors(fieldsToValidate);

		await Promise.all(
			this.validationRules.map((rule) => {
				// Skip this rule if we've defined a whitelist of fields to validate4
				if (fieldsToValidate && fieldsToValidate.indexOf(rule.target) < 0) {
					return Promise.resolve();
				}

				return new Promise(async (resolve) => {
					const args = [`${(this as any)[rule.target] || ''}`].concat(rule.args || []);

					// eslint-disable-next-line prefer-spread
					if (!rule.callback.apply(rule, args)) {
						if (!this.validationErrors.has(rule.target)) {
							this.validationErrors.set(rule.target, []);
						}
						this.validationErrors.get(rule.target)?.push(rule);
						isValid = false;
					}

					resolve(null);
				});
			}),
		);

		return isValid;
	}

	public getProp(key: string): ModelProp {
		return (this.constructor as any).props[key];
	}

	public getPropKeys(): string[] {
		return Object.keys((this.constructor as any).props);
	}

	public forEachProp(callback: (prop: string) => void) {
		this.getPropKeys().forEach(callback);
	}
}
