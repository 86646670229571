/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

interface IFormProps {
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const defaultProps: IFormProps = {
	onSubmit: () => {},
};

const Form = (providedProps: React.PropsWithChildren<Partial<IFormProps>>) => {
	const props = { ...defaultProps, ...providedProps };

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				props.onSubmit(e);
			}}
		>
			{props.children}
			<input type="submit" style={{ display: 'none' }} />
		</form>
	);
};

export default Form;
