import Axios from 'axios';
import UpdatePasswordRequest from '../model/request/UpdatePasswordRequest';
import User from '../model/User';

export default class UserService {
	public static async GetUserInfo(): Promise<User> {
		const response = await Axios.get<User>(`/auth/me`);

		return User.fromJSON(response.data);
	}

	public static async SaveUserInfo(user: User) {
		await Axios.post<User>(`/auth/me`, user);
	}

	public static async UpdatePassword(updatePasswordRequest: UpdatePasswordRequest) {
		await Axios.post(`/auth/me/password`, updatePasswordRequest);
	}
}
