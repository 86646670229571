import AnalyticsClient from '@cb/analytics-client';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios';
import { detect } from 'detect-browser';

interface QueuedEvent {
	eventName: string;
	payload?: unknown;
}

export default class AnalyticsService {
	private _client?: AnalyticsClient;

	private _queuedEvents: QueuedEvent[] = [];

	constructor(analyticsServerRootUri?: string) {
		if (analyticsServerRootUri) {
			this.connect(analyticsServerRootUri);
		}
	}

	public async setAnalyticsServerRootUri(analyticsServerRootUri: string) {
		await this.connect(analyticsServerRootUri);
	}

	private async connect(analyticsServerRootUri: string) {
		this._client = new AnalyticsClient(analyticsServerRootUri);

		this._client.setFingerprintChangedHandler((fingerprint) => {
			console.log('Analytics fingerprint', fingerprint);
			axios.defaults.headers['X-Analytics-Fingerprint'] = fingerprint;
		});

		const agent = await FingerprintJS.load();
		const details = await agent.get({});

		this.postEvent('Loaded', {
			browserInfo: detect(),
			components: {
				...details.components,
				canvas: null,
			},
		});

		await Promise.all(
			this._queuedEvents.map((queuedEvent) =>
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				this._client!.postEvent(queuedEvent.eventName, queuedEvent.payload),
			),
		);
	}

	private async postEvent(eventName: string, payload?: unknown) {
		if (this._client) {
			await this._client.postEvent(`Auth__${eventName}`, payload);
		} else {
			this._queuedEvents.push({
				eventName,
				payload,
			});
		}
	}
}
