import { observable } from 'mobx';
import AbstractModel from '../AbstractModel';
import { ValidationLength, ValidationRequired } from '../../decorator/Validation';
import { ModelProp } from '../../decorator/ModelProp';

export default class UpdatePasswordRequest extends AbstractModel<UpdatePasswordRequest> {
	@observable
	@ValidationRequired()
	@ModelProp('Current Password')
	public CurrentPassword?: string;

	@observable
	@ValidationRequired()
	@ValidationLength(12)
	@ModelProp('New Password')
	public NewPassword?: string;
}
