import React, { useCallback } from 'react';
import logo from '../static/img/logo.svg';

import AuthService from '../service/AuthService';
import { useQueryParam } from '../hook/useQueryParam';
import { useAsync } from '../hook/useAsync';
import Button, { BtnStyle } from './element/Button';
import ButtonGroup from './element/ButtonGroup';
import { useHistory } from 'react-router-dom';
import { Color } from './element/Types';

const ConfirmEmail = () => {
	const history = useHistory();
	const email = useQueryParam('email');
	const challenge = useQueryParam('challenge');

	const confirmEmail = useCallback(() => {
		return AuthService.ConfirmEmailAddress({
			Challenge: challenge,
			Email: email,
		});
	}, [challenge, email]);

	const { isResolved, isFinished, result } = useAsync(confirmEmail);

	let view = null;

	if (isFinished) {
		if (result && isResolved) {
			view = (
				<>
					<p>Success! Thank you for confirming your email address with us.</p>
					<ButtonGroup>
						<Button
							text="Login"
							style={BtnStyle.Solid}
							color={Color.StandardGreen}
							onClick={() => history.replace('/')}
						/>
					</ButtonGroup>
				</>
			);
		} else {
			view = (
				<>
					<p>Sorry, we could not verify your email address. Your link might be invalid.</p>
					<ButtonGroup>
						<Button
							text="Login"
							style={BtnStyle.Solid}
							color={Color.StandardGreen}
							onClick={() => history.replace('/')}
						/>
					</ButtonGroup>
				</>
			);
		}
	} else {
		view = <p>Please wait while we confirm your email address...</p>;
	}

	return (
		<div className="confirm_email-page form">
			<img src={logo} alt="Codebots" />

			<h1>Email confirmation</h1>

			{view}
		</div>
	);
};

export default ConfirmEmail;
