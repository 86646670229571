import React, { useEffect, useState } from 'react';
import { TextInputSize } from './element/TextInput';
import AuthService from '../service/AuthService';
import { useQueryParam } from '../hook/useQueryParam';
import { Link } from 'react-router-dom';
import Button, { BtnSize, BtnStyle, BtnWidth } from './element/Button';
import ButtonGroup from './element/ButtonGroup';
import TextInput from './element/TextInput';
import PasswordInput from './element/PasswordInput';

import SpinnerUtil from '../util/SpinnerUtil';
import AlertUtil from '../util/AlertUtil';

import LoginRequest from '../model/request/LoginRequest';

import logo from '../static/img/logo.svg';
import Form from './element/Form';
import { Color } from './element/Types';

const Login = () => {
	const challenge = useQueryParam('login_challenge');
	const email = useQueryParam('email');
	const [loginRequest] = useState(new LoginRequest());

	const [isLoading, setIsLoading] = useState(false);
	const [oauthClientName, setOauthClientName] = useState('');

	if (email && !loginRequest.Email) {
		loginRequest.Email = email;
	}

	useEffect(() => {
		if (challenge) {
			loginRequest.Challenge = challenge;

			AuthService.GetOauthClientFromLoginChallenge(challenge || '').then((client) => {
				setOauthClientName(client.ClientName || '');
			});
		} else {
			setTimeout(() => location.replace('/'), 0);
		}
	}, [challenge, loginRequest]);

	const login = async () => {
		const isValid = loginRequest.validate();

		if (isValid) {
			try {
				setIsLoading(true);
				SpinnerUtil.ShowSpinner();
				const loggedIn = await AuthService.Login(loginRequest);
				if (!loggedIn) {
					AlertUtil.ShowError('Invalid email and/or password.');
					setIsLoading(false);
					SpinnerUtil.HideSpinner();
				}
			} catch (err) {
				setIsLoading(false);
				SpinnerUtil.HideSpinner();

				AlertUtil.ShowError('Something unexpected happened. Please contact support.');
			} finally {
				// We don't un-hide the spinner, since we want it to keep showing while the redirect is in progress
			}
		}
	};

	// If there's no challenge, don't don't render anything.
	// The useEffect from above will automatically redirect away from this page as soon as it kicks in.
	if (!challenge) {
		return <></>;
	}

	return (
		<div className="login-page form">
			<img src={logo} alt="Codebots" />

			<h1>Sign in{oauthClientName ? ` to ${oauthClientName}` : ''}.</h1>

			<Form onSubmit={login}>
				<TextInput
					label="Email"
					placeholder="Email"
					id="email"
					size={TextInputSize.Massive}
					model={loginRequest}
					modelProperty="Email"
					validateModel={true}
					isDisabled={isLoading}
				/>
				<PasswordInput
					label="Password"
					placeholder="Password"
					id="password"
					size={TextInputSize.Massive}
					model={loginRequest}
					modelProperty="Password"
					validateModel={true}
					isDisabled={isLoading}
					isShowPasswordVisible={true}
				/>
			</Form>

			<ButtonGroup>
				<Button
					text="Sign in"
					style={BtnStyle.Solid}
					color={Color.Success}
					size={BtnSize.Medium}
					width={BtnWidth.FullWidth}
					onClick={login}
					isDisabled={isLoading}
				/>
			</ButtonGroup>

			<p className="login-forgot">
				<Link to="/forgot">Forgotten your details?</Link>
			</p>
		</div>
	);
};

export default Login;
