import { useState, useEffect } from 'react';

export const useAsync = (callback: () => Promise<unknown>) => {
	const [isResolved, setIsResolved] = useState(false);
	const [isRejected, setIsRejected] = useState(false);
	const [isFinished, setIsFinished] = useState(false);
	const [result, setResult] = useState<unknown>();

	useEffect(() => {
		callback()
			.then((resolution) => {
				setResult(resolution);
				setIsFinished(true);
				setIsResolved(true);
				setIsRejected(false);
			})
			.catch((reason) => {
				setResult(reason);
				setIsFinished(true);
				setIsResolved(false);
				setIsRejected(true);
			});
	}, [callback]);

	return { isResolved, isRejected, isFinished, result };
};
