import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TextInput from './element/TextInput';
import User from '../model/User';
import Config from '../Config';
import ButtonGroup from './element/ButtonGroup';
import Button, { BtnSize, BtnStyle } from './element/Button';
import { Color } from './element/Types';
import PasswordInput from './element/PasswordInput';
import UpdatePasswordRequest from '../model/request/UpdatePasswordRequest';
import logo from '../static/img/logo.svg';
import InputGroup from './element/InputGroup';
import UserService from '../service/UserService';
import SpinnerUtil from '../util/SpinnerUtil';
import AlertUtil from '../util/AlertUtil';
import AuthService from '../service/AuthService';
import Form from './element/Form';

interface IProfileProps {
	user: User;
}

const Profile = observer((props: IProfileProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isPasswordPristine, setIsPasswordPristine] = useState(true);
	const [, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [updatePasswordRequest] = useState(new UpdatePasswordRequest());

	const updateName = async () => {
		const isValid = props.user.validate(['GivenName', 'FamilyName']);
		if (isValid) {
			try {
				setIsLoading(true);
				SpinnerUtil.ShowSpinner();
				await UserService.SaveUserInfo(props.user);
				AlertUtil.ShowSuccess('Successfully updated profile information.');
			} catch (err) {
				AlertUtil.ShowError('Failed to update profile information.');
			} finally {
				setIsLoading(false);
				SpinnerUtil.HideSpinner();
			}
		}
	};

	const updatePassword = async () => {
		const isValid = updatePasswordRequest.validate();

		if (isValid) {
			try {
				setIsLoading(true);
				SpinnerUtil.ShowSpinner();
				await UserService.UpdatePassword(updatePasswordRequest);
				AlertUtil.ShowSuccess('Successfully changed your password.');
				updatePasswordRequest.reset();
			} catch (err) {
				AlertUtil.ShowError('Failed to change your password. Your current password may be incorrect.');
			} finally {
				setIsLoading(false);
				SpinnerUtil.HideSpinner();
			}
		}
	};

	let passwordLabel = 'New password';
	if (isPasswordPristine || !newPassword) {
		passwordLabel += ' (12 characters minimum)';
	} else if (newPassword.length < 12) {
		passwordLabel += ` (${12 - newPassword.length} characters remaining)`;
	}

	return (
		<div className="profile-page form">
			<img src={logo} alt="Codebots" />

			<div className="heading">
				<h1>Welcome, {props.user.GivenName}!</h1>
				<Button
					text="Logout"
					size={BtnSize.Inline}
					onClick={() => AuthService.Logout()}
					isDisabled={isLoading}
				/>
			</div>

			<p>
				Looking for App Studio? Head <a href={Config.PlatformLoginUri}>over here.</a>
			</p>

			<h4>Your details</h4>
			<Form onSubmit={updateName}>
				<InputGroup className="name">
					<TextInput
						placeholder="Given Name"
						label="Given Name"
						id="givenName"
						model={props.user}
						modelProperty="GivenName"
						validateModel={true}
					/>
					<TextInput
						placeholder="Family Name"
						label="Family Name"
						id="familyName"
						model={props.user}
						modelProperty="FamilyName"
						validateModel={true}
					/>
				</InputGroup>
			</Form>
			<ButtonGroup>
				<Button
					text="Save changes"
					style={BtnStyle.Solid}
					color={Color.Success}
					size={BtnSize.Standard}
					onClick={updateName}
					isDisabled={isLoading}
				/>
			</ButtonGroup>

			<h4>Your password</h4>
			<Form onSubmit={updatePassword}>
				<PasswordInput
					label="Current password"
					placeholder="Current password"
					id="currentPassword"
					model={updatePasswordRequest}
					modelProperty="CurrentPassword"
					validateModel={true}
					isDisabled={isLoading}
					isShowPasswordVisible={true}
					onValueChanged={(val) => setCurrentPassword(val as string)}
				/>
				<PasswordInput
					placeholder="New password"
					label={passwordLabel}
					id="newPassword"
					model={updatePasswordRequest}
					modelProperty="NewPassword"
					validateModel={true}
					isShowPasswordVisible={true}
					isCopyPasswordVisible={true}
					isGeneratePasswordVisible={true}
					isPasswordLengthVisible={true}
					onDirty={() => setIsPasswordPristine(false)}
					onValueChanged={(val) => setNewPassword(val as string)}
				/>
			</Form>
			<ButtonGroup>
				<Button
					text="Update password"
					style={BtnStyle.Solid}
					color={Color.Success}
					size={BtnSize.Standard}
					onClick={updatePassword}
					isDisabled={isLoading}
				/>
			</ButtonGroup>
		</div>
	);
});

export default Profile;
