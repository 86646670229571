import React from 'react';

interface ISpinnerProps {
	isVisible?: boolean;
}

const defaultProps: ISpinnerProps = {};

const Spinner = (providedProps: Partial<ISpinnerProps>) => {
	const props = { ...defaultProps, ...providedProps };

	if (!props.isVisible) {
		return null;
	}

	return (
		<div className="spinner-container">
			<div className="spinner-wrapper">
				<div className="spinner-object"></div>
			</div>
		</div>
	);
};

export default Spinner;
