import { useLocation } from 'react-router-dom';

export const useQueryParam = (key: string): string | undefined => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);

	if (params.has(key)) {
		return params.get(key) || '';
	}

	return undefined;
};
