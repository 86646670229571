import { AlertDisplayType, AlertType } from '../component/element/Alert';
import { globalStore } from '../GlobalStore';

export default class AlertUtil {
	public static ShowInfo(message: string, type: AlertType = AlertType.Dismissible) {
		globalStore.setAlertMessage(message, AlertDisplayType.Info, type);
	}

	public static ShowSuccess(message: string, type: AlertType = AlertType.Dismissible) {
		globalStore.setAlertMessage(message, AlertDisplayType.Success, type);
	}

	public static ShowError(message: string, type: AlertType = AlertType.Dismissible) {
		globalStore.setAlertMessage(message, AlertDisplayType.Error, type);
	}

	public static ShowWarning(message: string, type: AlertType = AlertType.Dismissible) {
		globalStore.setAlertMessage(message, AlertDisplayType.Warning, type);
	}

	public static HideAlert() {
		globalStore.setAlertMessage('');
	}
}
