import React, { useContext } from 'react';
import Config from '../Config';
import { observer } from 'mobx-react';
import globalStore from '../GlobalStore';
import Button from '@cb/solaris-react/components/Button';
import Profile from './Profile';
import styled from 'styled-components';
import { Scheme, Size, Variant } from '@cb/solaris-react/constants/System';

const Redirect = (uri: string) => {
	window.location.href = uri;
};

const Home = observer(() => {
	const store = useContext(globalStore);

	if (store.user.Id !== undefined) {
		return <Profile user={store.user} />;
	}

	return (
		<div className="home-page">
			<h1>Welcome.</h1>
			<Buttons>
				<Button
					className="bottom-left"
					variant={Variant.solid}
					scheme={Scheme.success}
					size={Size.large}
					onClick={() => Redirect(Config.PlatformLoginUri)}
					title="Login to App Studio"
				>
					Login to App Studio
				</Button>
				<Button
					className="bottom-right"
					variant={Variant.solid}
					scheme={Scheme.accent1}
					size={Size.large}
					onClick={() => Redirect(Config.GitlabLoginUri)}
					title="Login to GitLab"
				>
					Login to GitLab
				</Button>
			</Buttons>
		</div>
	);
});

export default Home;

const Buttons = styled.div`
	display: grid;
	column-gap: ${(props) => props.theme.spacing.md};
	row-gap: ${(props) => props.theme.spacing.md};

	.top {
		grid-column: 1 / 3;
		grid-row: 1 / 2;
	}

	.bottom-left {
		grid-column: 1 / 2;
		grid-row: 2 / 3;
	}

	.bottom-right {
		grid-column: 2 / 3;
		grid-row: 2 / 3;
	}
`;
