/* eslint-disable @typescript-eslint/no-empty-function */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { IInputProps, InputDisplayType } from './Types';

export enum SelectInputSize {
	Standard = 'standard',
	Medium = 'medium',
	Massive = 'massive',
	Heading = 'heading',
}

export interface ISelectInputOption {
	value: string | number;
	display?: string;
}

interface ISelectInputProps extends IInputProps {
	size: SelectInputSize;
	options: ISelectInputOption[];
}

const defaultProps: ISelectInputProps = {
	id: '',
	label: '',
	placeholder: 'Please select',
	displayType: InputDisplayType.Block,
	size: SelectInputSize.Standard,
	options: [],
	labelVisible: true,
	onValueChanged: () => {},
	onDirty: () => {},
};

const getClassName = (props: ISelectInputProps, hasValidationErrors: boolean): string => {
	const classes: string[] = [
		'input-group',
		`input-group__select`,
		`input-group--${props.displayType}`,
		`input--size--${props.size}`,
	];

	if (props.className) {
		classes.push(props.className);
	}

	if (props.isRequired) {
		classes.push('input-group-wrapper--is-required');
	}

	if (hasValidationErrors) {
		classes.push('input-group--error');
	}

	if (props.description) {
		classes.push('input-group-wrapper--sub-description');
	}

	return classes.join(' ');
};

const SelectInput = observer((providedProps: React.PropsWithChildren<Partial<ISelectInputProps>>) => {
	const props = { ...defaultProps, ...providedProps };

	const [isPristine, setIsPristine] = useState(true);
	const [value, setValue] = useState<string>(
		props.model && props.modelProperty ? props.model.get(props.modelProperty) || '' : '',
	);

	const validationErrors = [
		...(props.model?.validationErrors.get(props.modelProperty || '')?.map((e) => e.message) || []),
		...(props.errors || []),
	];

	const performValidation = () => {
		if (props.model && props.modelProperty && props.validateModel) {
			props.model.validate([props.modelProperty]);
		}
	};

	const onValueChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const val = e.target.value;

		setValue(val);

		if (isPristine) {
			setIsPristine(false);
			props.onDirty();
		}

		if (props.model && props.modelProperty) {
			props.model.set(props.modelProperty, val);
		}

		props.onValueChanged(val);
		performValidation();
	};

	const propDependency = props.model && props.modelProperty ? props.model.get(props.modelProperty) : undefined;

	useEffect(() => {
		if (props.model && props.modelProperty) {
			setValue(props.model.get(props.modelProperty));
		}
	}, [props.model, props.modelProperty, propDependency]);

	return (
		<div className={getClassName(props, validationErrors.length ? true : false)} aria-live="assertive">
			{props.labelVisible ? <label htmlFor={props.id}>{props.label}</label> : null}
			{props.tooltip ? (
				<div className="tooltip icon-tooltip">
					<span className="tooltip__content">{props.tooltip}</span>
				</div>
			) : null}
			<select
				id={props.id}
				name={props.id}
				value={value ?? ''}
				onChange={onValueChanged}
				placeholder={props.placeholder}
				onBlur={() => {
					setIsPristine(false);
					performValidation();
				}}
				disabled={props.isDisabled || props.isReadOnly}
				aria-label={props.ariaLabelOverride || (typeof props.label == 'string' ? props.label : '')}
				aria-describedby={props.description ? `${props.id}-description` : props.tooltip || undefined}
			>
				{props.placeholder ? <option value="">{props.placeholder}</option> : null}
				{props.options.map((option, i) => (
					<option key={i} value={option.value}>
						{option.display || option.value}
					</option>
				))}
			</select>
			{props.description ? (
				<p className="input-group__sub-description" id={`${props.id}-description`}>
					{props.description}
				</p>
			) : null}
			{validationErrors.length ? (
				<div className="input-group__error-text">
					{validationErrors.map((message, i) => (
						<p className="txt-sm-body" key={i}>
							{message}
						</p>
					))}
				</div>
			) : null}
			{props.children}
		</div>
	);
});

export default SelectInput;
