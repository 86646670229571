class Config {
	get PlatformLoginUri() {
		return '/auth/platformLogin';
	}

	get GitlabLoginUri() {
		return '/auth/gitlabLogin';
	}

	get SignupUri() {
		return `/signup`;
	}

	get LogoutUri() {
		return `/auth/logout`;
	}
}

export default new Config();
