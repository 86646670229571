import { observable } from 'mobx';
import AbstractModel from '../AbstractModel';
import { ValidationEmail, ValidationLength, ValidationRequired } from '../../decorator/Validation';
import { ModelProp } from '../../decorator/ModelProp';

export default class ForgotCompleteRequest extends AbstractModel<ForgotCompleteRequest> {
	@observable
	@ValidationRequired()
	@ValidationEmail()
	@ModelProp()
	public Email?: string;

	@observable
	@ValidationRequired()
	@ValidationLength(12)
	@ModelProp()
	public Password?: string;

	@observable
	@ValidationRequired()
	@ModelProp()
	public Challenge?: string;
}
