/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';

export enum AlertDisplayType {
	Success = 'success',
	Info = 'info',
	Warning = 'warning',
	Error = 'error',
}

export enum AlertType {
	Static,
	Flash,
	Dismissible,
}

interface IAlertProps {
	message: string;
	displayType: AlertDisplayType;
	type: AlertType;
	flashTime?: number;
	isVisible?: boolean;
	onDismissed: () => void;
}

const getClassName = (props: IAlertProps): string => {
	return `alert alert__${props.displayType}`;
};

const defaultProps: IAlertProps = {
	message: '',
	displayType: AlertDisplayType.Info,
	type: AlertType.Dismissible,
	flashTime: 5000,
	onDismissed: () => {},
};

const Alert = (providedProps: Partial<IAlertProps>) => {
	const props = { ...defaultProps, ...providedProps };

	const [isVisible, setIsVisible] = useState(props.isVisible || false);

	useEffect(() => {
		setIsVisible(props.isVisible || false);
	}, [props.isVisible]);

	if (props.type === AlertType.Flash) {
		setTimeout(() => {
			setIsVisible(false);
			props.onDismissed();
		}, props.flashTime);
	}

	if (!isVisible) {
		return null;
	}

	return (
		<div className="alert-container" aria-live="assertive">
			<div className={getClassName(props)} aria-live="assertive">
				<p>{props.message}</p>
				{props.type === AlertType.Dismissible ? (
					<button
						className="btn btn--text icon-right icon-cross"
						aria-label="close message"
						onClick={() => {
							setIsVisible(false);
							props.onDismissed();
						}}
					></button>
				) : null}
			</div>
		</div>
	);
};

export default Alert;
