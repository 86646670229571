/* eslint-disable @typescript-eslint/no-empty-function */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { IInputProps, InputDisplayType } from './Types';

export enum SelectInputSize {
	Standard = 'standard',
	Medium = 'medium',
	Massive = 'massive',
	Heading = 'heading',
}

type ISelectInputProps = IInputProps;

const defaultProps: ISelectInputProps = {
	id: '',
	label: '',
	placeholder: 'Please select',
	displayType: InputDisplayType.Inline,
	labelVisible: true,
	onValueChanged: () => {},
	onDirty: () => {},
};

const getClassName = (props: ISelectInputProps, hasValidationErrors: boolean): string => {
	const classes: string[] = ['input-group', `input-group__checkbox`, `input-group--${props.displayType}`];

	if (props.className) {
		classes.push(props.className);
	}

	if (props.isRequired) {
		classes.push('input-group-wrapper--is-required');
	}

	if (hasValidationErrors) {
		classes.push('input-group--error');
	}

	if (props.description) {
		classes.push('input-group-wrapper--sub-description');
	}

	return classes.join(' ');
};

const CheckboxInput = observer((providedProps: React.PropsWithChildren<Partial<ISelectInputProps>>) => {
	const props = { ...defaultProps, ...providedProps };

	const [isPristine, setIsPristine] = useState(true);
	const [isChecked, setIsChecked] = useState<boolean>(
		props.model && props.modelProperty ? props.model.get(props.modelProperty) || false : false,
	);

	const validationErrors = [
		...(props.model?.validationErrors.get(props.modelProperty || '')?.map((e) => e.message) || []),
		...(props.errors || []),
	];

	const performValidation = () => {
		if (props.model && props.modelProperty && props.validateModel) {
			props.model.validate([props.modelProperty]);
		}
	};

	const onValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.target.checked;

		setIsChecked(val);

		if (isPristine) {
			setIsPristine(false);
			props.onDirty();
		}

		if (props.model && props.modelProperty) {
			props.model.set(props.modelProperty, val);
		}

		props.onValueChanged(val);
		performValidation();
	};

	const propDependency = props.model && props.modelProperty ? props.model.get(props.modelProperty) : undefined;

	useEffect(() => {
		if (props.model && props.modelProperty) {
			setIsChecked(props.model.get(props.modelProperty));
		}
	}, [props.model, props.modelProperty, propDependency]);

	return (
		<div className={getClassName(props, validationErrors.length ? true : false)} aria-live="assertive">
			{props.labelVisible ? <label htmlFor={props.id}>{props.label}</label> : null}
			{props.tooltip ? (
				<div className="tooltip icon-tooltip">
					<span className="tooltip__content">{props.tooltip}</span>
				</div>
			) : null}
			<input
				id={props.id}
				name={props.id}
				type="checkbox"
				onChange={onValueChanged}
				placeholder={props.placeholder}
				onBlur={() => {
					setIsPristine(false);
					performValidation();
				}}
				disabled={props.isDisabled || props.isReadOnly}
				aria-label={props.ariaLabelOverride || (typeof props.label == 'string' ? props.label : '')}
				aria-describedby={props.description ? `${props.id}-description` : props.tooltip || undefined}
				checked={isChecked}
			/>
			{props.description ? (
				<p className="input-group__sub-description" id={`${props.id}-description`}>
					{props.description}
				</p>
			) : null}
			{validationErrors.length ? (
				<div className="input-group__error-text">
					{validationErrors.map((message, i) => (
						<p className="txt-sm-body" key={i}>
							{message}
						</p>
					))}
				</div>
			) : null}
			{props.children}
		</div>
	);
});

export default CheckboxInput;
