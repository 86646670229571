import React from 'react';
import { useQueryParam } from '../hook/useQueryParam';
import ForgotStart from './ForgotStart';
import ForgotComplete from './ForgotComplete';
import logo from '../static/img/logo.svg';

const Forgot = () => {
	const email = useQueryParam('email');
	const challenge = useQueryParam('challenge');

	return (
		<div className="forgot-page form">
			<img src={logo} alt="Codebots" />

			<h1>Forgot password.</h1>

			{challenge ? <ForgotComplete email={email} challenge={challenge} /> : <ForgotStart />}
		</div>
	);
};

export default Forgot;
