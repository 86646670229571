import AbstractModel from '../../model/AbstractModel';

export enum Color {
	Primary = 'primary',
	Secondary = 'secondary',
	Brand01 = 'brand-01',
	Brand02 = 'brand-02',
	Accent = 'accent',
	Success = 'success',
	Error = 'error',
	StandardYellow = 'standard-yellow',
	StandardRed = 'standard-red',
	StandardGreen = 'standard-green',
	StandardOrange = 'standard-orange',
	StandardBlue = 'standard-blue',
}

export enum InputDisplayType {
	Block = 'block',
	Inline = 'inline',
}

export interface IInputProps {
	id: string;
	displayType: InputDisplayType;
	className?: string;
	label: string | JSX.Element;
	labelVisible?: boolean;
	description?: string | JSX.Element;
	placeholder?: string;
	isRequired?: boolean;
	isReadOnly?: boolean;
	isDisabled?: boolean;
	tooltip?: string;
	errors?: string[];

	onValueChanged: (val: unknown) => void;
	onDirty: () => void;

	model?: AbstractModel<unknown>;
	modelProperty?: string;
	validateModel?: boolean;

	ariaLabelOverride?: string;
}
