import { observable } from 'mobx';
import Group from './Group';
import AbstractModel from './AbstractModel';
import { ValidationEmail, ValidationRequired } from '../decorator/Validation';
import { ModelProp } from '../decorator/ModelProp';

export default class User extends AbstractModel<User> {
	@observable
	@ModelProp()
	public Id?: number;

	@observable
	@ValidationRequired()
	@ModelProp('Given Name')
	public GivenName?: string;

	@observable
	@ValidationRequired()
	@ModelProp('Family Name')
	public FamilyName?: string;

	@observable
	@ValidationRequired()
	@ValidationEmail()
	@ModelProp()
	public Email?: string;

	@observable
	@ModelProp()
	public Groups?: Group[];
}
