import { globalStore } from '../GlobalStore';

export default class SpinnerUtil {
	public static ShowSpinner() {
		globalStore.setIsSpinnerVisible(true);
	}

	public static HideSpinner() {
		globalStore.setIsSpinnerVisible(false);
	}
}
