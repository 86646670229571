/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Color } from './Types';

export enum BtnSize {
	Inline = 'inline',
	Standard = 'standard',
	Medium = 'medium',
	Massive = 'massive',
}

export enum BtnStyle {
	Solid = 'solid',
	Outline = 'outline',
	Text = 'text',
}

export enum BtnWidth {
	Auto = 'auto',
	FullWidth = 'full',
}

export enum BtnIconPosition {
	Left = 'left',
	Right = 'right',
}

interface IButtonProps {
	className?: string;
	text?: string;
	style: BtnStyle;
	color: Color;
	size: BtnSize;
	width: BtnWidth;
	icon?: string;
	isIconOnly?: boolean;
	iconPosition: BtnIconPosition;
	onClick: () => void;
	isDisabled?: boolean;
}

const defaultProps: IButtonProps = {
	style: BtnStyle.Outline,
	color: Color.Primary,
	size: BtnSize.Medium,
	width: BtnWidth.Auto,
	onClick: () => {},
	iconPosition: BtnIconPosition.Left,
};

const getClassName = (props: IButtonProps): string => {
	const classes: string[] = ['btn'];

	if (props.className) {
		classes.push(props.className);
	}

	classes.push(`btn--${props.style}`);
	classes.push(`btn--${props.color}`);
	classes.push(`btn--${props.size}`);
	classes.push(`btn--${props.width}-width`);

	if (props.icon) {
		classes.push(`btn--icon`);
		classes.push(`icon--${props.iconPosition}`);
		classes.push(`icon--${props.icon}`);
	}

	return classes.join(' ');
};

const Button = (providedProps: Partial<IButtonProps>) => {
	const props = { ...defaultProps, ...providedProps };

	return (
		<button
			className={getClassName(props)}
			onClick={props.onClick}
			aria-label={props.text || ''}
			disabled={props.isDisabled}
		>
			{props.isIconOnly ? '' : props.text || ''}
		</button>
	);
};

export default Button;
