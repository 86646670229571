import React from 'react';
import { useQueryParam } from '../hook/useQueryParam';

const Error = () => {
	const errorType = useQueryParam('error');
	const errorDescription = useQueryParam('error_description');
	const errorHint = useQueryParam('error_hint');

	return (
		<div>
			<h1>Oh dear...</h1>
			{errorType ? <h2>{errorType}</h2> : null}
			{errorDescription ? <p>{errorDescription}</p> : null}
			{errorHint ? <p>{errorHint}</p> : null}
		</div>
	);
};

export default Error;
