import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../service/AuthService';
import Button, { BtnStyle } from './element/Button';
import ButtonGroup from './element/ButtonGroup';
import TextInput, { TextInputSize } from './element/TextInput';
import { Color } from './element/Types';
import ForgotStartRequest from '../model/request/ForgotStartRequest';
import SpinnerUtil from '../util/SpinnerUtil';
import AlertUtil from '../util/AlertUtil';
import Form from './element/Form';

const ForgotStart = () => {
	const history = useHistory();

	const [forgotStartRequest] = useState(new ForgotStartRequest());
	const [isLoading, setIsLoading] = useState(false);
	const [isComplete, setIsComplete] = useState(false);

	const forgot = async () => {
		const isValid = forgotStartRequest.validate();

		if (isValid) {
			try {
				setIsLoading(true);
				SpinnerUtil.ShowSpinner();
				await AuthService.RequestResetPassword(forgotStartRequest);
				setIsComplete(true);
			} catch (err) {
				AlertUtil.ShowError('Failed to reset your password.');
			} finally {
				setIsLoading(false);
				SpinnerUtil.HideSpinner();
			}
		}
	};

	if (isComplete) {
		return (
			<>
				<p>
					Thank you. If your email address matched our records, you will be sent an email containing a link to
					reset your password. Please check your inbox for further instructions.
				</p>
				<ButtonGroup>
					<Button
						text="Back to login"
						style={BtnStyle.Outline}
						onClick={() => history.push('/')}
						isDisabled={isLoading}
					/>
				</ButtonGroup>
			</>
		);
	}

	return (
		<>
			<Form onSubmit={forgot}>
				<TextInput
					label="Email"
					placeholder="Email"
					id="email"
					size={TextInputSize.Massive}
					model={forgotStartRequest}
					modelProperty="Email"
					validateModel={true}
					isDisabled={isLoading}
				/>
			</Form>
			<ButtonGroup>
				<Button
					text="Send email"
					style={BtnStyle.Solid}
					color={Color.Success}
					onClick={forgot}
					isDisabled={isLoading}
				/>
				<Button
					text="Back to login"
					style={BtnStyle.Outline}
					onClick={() => history.push('/')}
					isDisabled={isLoading}
				/>
			</ButtonGroup>
		</>
	);
};

export default ForgotStart;
