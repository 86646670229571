import { observable } from 'mobx';
import AbstractModel from '../AbstractModel';
import { ValidationEmail, ValidationRequired } from '../../decorator/Validation';
import { ModelProp } from '../../decorator/ModelProp';

export default class LoginRequest extends AbstractModel<LoginRequest> {
	@observable
	@ValidationRequired()
	@ValidationEmail()
	@ModelProp()
	public Email?: string;

	@observable
	@ValidationRequired()
	@ModelProp()
	public Password?: string;

	@observable
	@ValidationRequired()
	@ModelProp()
	public Challenge?: string;
}
