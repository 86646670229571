import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../service/AuthService';
import Button, { BtnStyle } from './element/Button';
import ButtonGroup from './element/ButtonGroup';
import TextInput from './element/TextInput';
import ForgotCompleteRequest from '../model/request/ForgotCompleteRequest';
import { Color } from './element/Types';
import { useForceRender } from '../hook/useForceRender';
import PasswordInput from './element/PasswordInput';
import SpinnerUtil from '../util/SpinnerUtil';
import Form from './element/Form';

interface IForgotCompleteProps {
	challenge: string;
	email?: string;
}

const ForgotComplete = (props: IForgotCompleteProps) => {
	const history = useHistory();
	const forceRender = useForceRender();

	const [isPasswordPristine, setIsPasswordPristine] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [didFail, setDidFail] = useState(false);
	const [forgotCompleteRequest] = useState(new ForgotCompleteRequest());

	if (props.email) {
		forgotCompleteRequest.Email = props.email;
	}

	const forgot = async () => {
		forgotCompleteRequest.Challenge = props.challenge;

		const isValid = forgotCompleteRequest.validate();

		if (isValid) {
			try {
				setIsLoading(true);
				SpinnerUtil.ShowSpinner();
				await AuthService.CompleteResetPassword(forgotCompleteRequest);
				setIsComplete(true);
			} catch (err) {
				setDidFail(true);
			} finally {
				setIsLoading(false);
				SpinnerUtil.HideSpinner();
			}
		}
	};

	if (isComplete) {
		return (
			<>
				<p>Your password has successfully been reset!</p>
				<ButtonGroup>
					<Button
						text="Login"
						style={BtnStyle.Solid}
						color={Color.StandardGreen}
						onClick={() => history.replace('/')}
					/>
				</ButtonGroup>
			</>
		);
	}

	if (didFail) {
		return (
			<>
				<p>Oh dear, it looks like this password reset link is invalid, or has expired.</p>
				<ButtonGroup>
					<Button
						text="Try again"
						style={BtnStyle.Solid}
						color={Color.StandardOrange}
						onClick={() => history.replace('/forgot')}
					/>
				</ButtonGroup>
			</>
		);
	}

	let passwordLabel = 'New Password';
	if (isPasswordPristine || !forgotCompleteRequest.Password) {
		passwordLabel += ' (12 characters minimum)';
	} else if (forgotCompleteRequest.Password.length < 12) {
		passwordLabel += ` (${12 - forgotCompleteRequest.Password.length} characters remaining)`;
	}

	return (
		<>
			<Form onSubmit={forgot}>
				<TextInput
					label="Email"
					placeholder="Email"
					id="email"
					model={forgotCompleteRequest}
					modelProperty="Email"
					validateModel={true}
					isDisabled={isLoading}
				/>
				<PasswordInput
					placeholder="Password"
					label={passwordLabel}
					id="password"
					model={forgotCompleteRequest}
					modelProperty="Password"
					validateModel={true}
					isShowPasswordVisible={true}
					isCopyPasswordVisible={true}
					isGeneratePasswordVisible={true}
					isPasswordLengthVisible={true}
					onDirty={() => setIsPasswordPristine(false)}
					onValueChanged={forceRender}
				/>
			</Form>

			<ButtonGroup>
				<Button
					text="Set new password"
					style={BtnStyle.Solid}
					color={Color.StandardGreen}
					onClick={forgot}
					isDisabled={isLoading}
				/>
			</ButtonGroup>
		</>
	);
};

export default ForgotComplete;
