/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import AuthService from '../../service/AuthService';
import TextInput, { TextInputSize, TextInputType } from './TextInput';
import copyToClipboard from 'copy-to-clipboard';

import { IInputProps, InputDisplayType } from './Types';
import AlertUtil from '../../util/AlertUtil';
import { AlertType } from './Alert';
import { observer } from 'mobx-react';
import { useForceRender } from '../../hook/useForceRender';

interface IPasswordInputProps extends IInputProps {
	size: TextInputSize;
	isShowPasswordVisible?: boolean;
	isGeneratePasswordVisible?: boolean;
	isCopyPasswordVisible?: boolean;
	isPasswordLengthVisible?: boolean;
}

const defaultProps: IPasswordInputProps = {
	id: '',
	label: '',
	displayType: InputDisplayType.Block,
	size: TextInputSize.Standard,
	onValueChanged: () => {},
	onDirty: () => {},
};

const PasswordInput = observer((providedProps: Partial<IPasswordInputProps>) => {
	const props = { ...defaultProps, ...providedProps };

	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [, setIsPristine] = useState(true);
	const forceRender = useForceRender();

	const copyPasswordToClipboard = () => {
		if (props.model && props.modelProperty) {
			if (copyToClipboard(props.model.get(props.modelProperty))) {
				AlertUtil.ShowSuccess('Successfully copied password to clipboard', AlertType.Flash);
			} else {
				AlertUtil.ShowError('Failed to copy to clipboard.', AlertType.Flash);
			}
		}
	};

	const generatePassword = () => {
		if (props.model && props.modelProperty) {
			props.model.set(props.modelProperty, AuthService.GenerateSecurePassword());
			setIsPasswordVisible(true);
			copyPasswordToClipboard();
			forceRender();
			setIsPristine(false);
			props.onDirty();
			props.onValueChanged(props.model.get(props.modelProperty));
		}
	};

	let passwordActionsView: JSX.Element | undefined = undefined;
	let passwordGenerateView: JSX.Element | undefined = undefined;

	if (props.isShowPasswordVisible || props.isCopyPasswordVisible) {
		passwordActionsView = (
			<div className={`input-group__password-actions`}>
				{props.isShowPasswordVisible ? (
					<div
						className={`icon icon-right icon-look${isPasswordVisible ? '' : '-off'}`}
						title={`${isPasswordVisible ? 'Hide' : 'Show'} password`}
						onClick={() => setIsPasswordVisible(!isPasswordVisible)}
					></div>
				) : null}
				{props.isCopyPasswordVisible ? (
					<div
						className="icon icon-right icon-file-copy"
						title="Copy password"
						onClick={() => copyPasswordToClipboard()}
					></div>
				) : null}
			</div>
		);
	}

	if (props.isGeneratePasswordVisible) {
		passwordGenerateView = (
			<a
				href="#"
				onClick={(e) => {
					e.preventDefault();
					generatePassword();
				}}
			>
				Generate a secure password for me
			</a>
		);
	}

	return (
		<TextInput
			type={isPasswordVisible ? TextInputType.Text : TextInputType.Password}
			className={'input-group__password input-group-wrapper__password-actions'}
			description={passwordGenerateView}
			{...props}
			onValueChanged={(val) => {
				setIsPristine(false);
				forceRender();
				props.onValueChanged(val);
			}}
			onDirty={() => {
				setIsPristine(false);
				props.onDirty();
			}}
		>
			{passwordActionsView}
		</TextInput>
	);
});

export default PasswordInput;
