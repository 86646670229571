import React from 'react';
import { InputDisplayType } from './Types';

interface IInputGroupProps {
	display: InputDisplayType;
	className?: string;
}

const defaultProps: IInputGroupProps = {
	display: InputDisplayType.Block,
};

const InputGroup = (providedProps: React.PropsWithChildren<Partial<IInputGroupProps>>) => {
	const props = { ...defaultProps, ...providedProps };

	return (
		<div className={`input-group-wrapper input-group-${props.display} ${props.className}`}>{props.children}</div>
	);
};

export default InputGroup;
