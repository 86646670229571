import { configure } from 'mobx';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import '@cb/solaris-css/main.scss';
import './css/main.scss';

// Configure MobX
configure({
	useProxies: 'ifavailable',
	enforceActions: 'observed',
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);
