import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useQueryParam } from '../hook/useQueryParam';
import AuthService, { AuthServiceHttpError } from '../service/AuthService';
import Button, { BtnStyle } from './element/Button';
import ButtonGroup from './element/ButtonGroup';
import TextInput, { TextInputType } from './element/TextInput';
import SelectInput, { ISelectInputOption } from './element/SelectInput';
import CheckboxInput from './element/CheckboxInput';
import logo from '../static/img/logo.svg';
import PasswordInput from './element/PasswordInput';
import { Color } from './element/Types';
import SpinnerUtil from '../util/SpinnerUtil';
import AlertUtil from '../util/AlertUtil';
import { useForceRender } from '../hook/useForceRender';
import SignupRequest from '../model/request/SignupRequest';
import Form from './element/Form';

const roleOptions: ISelectInputOption[] = [
	{ value: 'Developer' },
	{ value: 'CEO/Founder' },
	{ value: 'Manager' },
	{ value: 'Other' },
];

const companyTypeOptions: ISelectInputOption[] = [
	{ value: 'Software Development' },
	{ value: 'Marketing Agency' },
	{ value: 'In-house Development' },
	{ value: 'Freelancer' },
	{ value: 'Other' },
];

const termsAndConditionsText = (
	<>
		I agree that my use of the Codebots platform is subject to my acceptance and consent of the Codebots{' '}
		<a href="https://codebots.com/privacy-policy" target="_blank" rel="noreferrer">
			privacy policy
		</a>{' '}
		and{' '}
		<a href="https://codebots.com/full-software-licence" target="_blank" rel="noreferrer">
			software licence
		</a>
		.
	</>
);

const termsAndConditionsTextPlain =
	'I agree that my use of the Codebots platform is subject to my acceptance and consent of the Codebots privacy policy and software licence.';

const Signup = () => {
	const history = useHistory();
	const [signupRequest] = useState(new SignupRequest());
	const redirect = useQueryParam('redirect');
	const email = useQueryParam('email');
	const invitation = useQueryParam('invitation');
	const refCode = useQueryParam('r');

	const [isPasswordPristine, setIsPasswordPristine] = useState(true);
	const forceRender = useForceRender();
	const [isLoading, setIsLoading] = useState(false);

	if (email && !signupRequest.Email) {
		signupRequest.Email = email;
	}

	const completeSignup = async () => {
		const isValid = signupRequest.validate();

		if (isValid) {
			try {
				setIsLoading(true);
				SpinnerUtil.ShowSpinner();
				await AuthService.Signup(signupRequest, redirect, refCode);
			} catch (err) {
				if (err instanceof AuthServiceHttpError) {
					AlertUtil.ShowError(err.getErrorMessage());
				} else {
					AlertUtil.ShowError('Something unexpected happened, please contact support.');
				}
				SpinnerUtil.HideSpinner();
				setIsLoading(false);
			} finally {
				// Don't hide the spinner here, since we are waiting for the redirect and want it to keep showing
			}
		}
	};

	let passwordLabel = 'Password';
	if (isPasswordPristine || !signupRequest.Password) {
		passwordLabel += ' (12 characters minimum)';
	} else if (signupRequest.Password.length < 12) {
		passwordLabel += ` (${12 - signupRequest.Password.length} characters remaining)`;
	}

	return (
		<div className="signup-page form">
			<img src={logo} alt="Codebots" />

			{invitation ? (
				<>
					<h1>
						{invitation
							? `${invitation} has invited you to collaborate!`
							: 'Welcome to Codebots! Please add your details below to create an account.'}
					</h1>

					{invitation ? <p>Please fill out your details to create your Codebots account</p> : null}

					<Form onSubmit={completeSignup}>
						<TextInput
							placeholder="Full name"
							label="Full name"
							id="fullName"
							model={signupRequest}
							modelProperty="FullName"
							validateModel={true}
						/>
						<TextInput
							type={TextInputType.Email}
							placeholder="Email address"
							label="Email address"
							id="email"
							model={signupRequest}
							modelProperty="Email"
							validateModel={true}
						/>
						<PasswordInput
							placeholder="Password"
							label={passwordLabel}
							id="password"
							model={signupRequest}
							modelProperty="Password"
							validateModel={true}
							isShowPasswordVisible={true}
							isCopyPasswordVisible={true}
							isGeneratePasswordVisible={true}
							isPasswordLengthVisible={true}
							onDirty={() => setIsPasswordPristine(false)}
							onValueChanged={forceRender}
						/>
						<SelectInput
							label="Your role"
							id="role"
							model={signupRequest}
							modelProperty="Role"
							validateModel={true}
							options={roleOptions}
						/>
						<SelectInput
							label="Company Type"
							id="companyType"
							model={signupRequest}
							modelProperty="CompanyType"
							validateModel={true}
							options={companyTypeOptions}
						/>
						<CheckboxInput
							label={termsAndConditionsText}
							ariaLabelOverride={termsAndConditionsTextPlain}
							id="agree"
							model={signupRequest}
							modelProperty="AcceptTermsAndConditions"
							validateModel={true}
						/>
					</Form>

					<ButtonGroup>
						<Button
							text="Signup"
							style={BtnStyle.Solid}
							color={Color.Success}
							onClick={completeSignup}
							isDisabled={isLoading}
						/>
						<Button
							text="Back to login"
							style={BtnStyle.Outline}
							onClick={() => history.push('/')}
							isDisabled={isLoading}
						/>
					</ButtonGroup>
				</>
			) : (
				<>
					<h1>Sorry, we are not currently accepting any new signups.</h1>

					<p>
						Please contact us at <a href="mailto:support@codebots.com">support@codebots.com</a> if you would
						like a demo.
					</p>
				</>
			)}
		</div>
	);
};

export default Signup;
