import { createContext } from 'react';
import { observable, action, makeObservable } from 'mobx';
import User from './model/User';
import { AlertDisplayType, AlertType } from './component/element/Alert';
import IEnvironment from './type/IEnvironment';

export class GlobalStore {
	constructor() {
		makeObservable(this);
	}

	@observable
	public user: User = new User();

	@action
	public setUser(user: User) {
		this.user = user;
	}

	@observable
	public alertMessage = '';
	@observable
	public alertMessageDisplayType: AlertDisplayType = AlertDisplayType.Info;
	@observable
	public alertMessageType: AlertType = AlertType.Dismissible;

	@action
	public setAlertMessage(message: string, displayType = AlertDisplayType.Info, type = AlertType.Dismissible) {
		this.alertMessage = message;
		this.alertMessageDisplayType = displayType;
		this.alertMessageType = type;
	}

	@observable
	public isSpinnerVisible = false;

	@action
	public setIsSpinnerVisible(isVisible: boolean) {
		this.isSpinnerVisible = isVisible;
	}

	@observable
	public environment?: IEnvironment;

	@action
	public setEnvironment(environment: IEnvironment) {
		this.environment = environment;
	}
}

export const globalStore = new GlobalStore();

export default createContext(globalStore);
