import { observable } from 'mobx';
import AbstractModel from '../AbstractModel';
import {
	ValidationEmail,
	ValidationExactEquals,
	ValidationLength,
	ValidationRequired,
} from '../../decorator/Validation';
import { ModelProp } from '../../decorator/ModelProp';

export default class SignupRequest extends AbstractModel<SignupRequest> {
	@observable
	@ValidationRequired()
	@ModelProp('Full Name')
	public FullName?: string;

	@observable
	@ValidationRequired()
	@ValidationEmail()
	@ModelProp()
	public Email?: string;

	@observable
	@ValidationRequired()
	@ValidationLength(12)
	@ModelProp()
	public Password?: string;

	@observable
	@ValidationRequired()
	@ModelProp()
	public Role?: string;

	@observable
	@ValidationRequired()
	@ModelProp()
	public CompanyType?: string;

	@observable
	@ValidationExactEquals(true, 'You must agree to the terms & conditions.')
	@ModelProp()
	public AcceptTermsAndConditions?: boolean;
}
