/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelProp } from '../model/AbstractModel';
import IValidationRule from '../type/IValidationRule';
import validator from 'validator';

import Symbols from '../Symbols';

const addValidationToTarget = (target: any, rule: IValidationRule) => {
	if (!target[Symbols.Validators]) {
		target[Symbols.Validators] = Array<IValidationRule>();
	}
	target[Symbols.Validators].push(rule);
};

const getDisplayName = (target: any, key: string): string => {
	if (target.constructor.props && target.constructor.props[key]) {
		const displayName = (target.constructor.props[key] as ModelProp).displayName;
		return displayName ?? key;
	} else {
		console.error('no can do on', key, target);
	}

	return key;
};

export function ValidationRequired(message?: string, options?: validator.IsLengthOptions) {
	return function (target: any, key: string) {
		addValidationToTarget(target, {
			target: key,
			message: message || `${getDisplayName(target, key)} is required.`,
			callback: validator.isLength,
			args: [
				{
					...options,
					min: 1,
				} as validator.IsLengthOptions,
			],
		});
	};
}

export function ValidationExactEquals(value: any, message?: string) {
	return function (target: any, key: string) {
		addValidationToTarget(target, {
			target: key,
			message: message || `${getDisplayName(target, key)} should equal '${value}'`,
			callback: (val) => val === value,
			args: [],
		});
	};
}

export function ValidationContains(seed: string, message?: string) {
	return function (target: any, key: string) {
		addValidationToTarget(target, {
			target: key,
			message: message || `${getDisplayName(target, key)} should contain '${seed}'`,
			callback: validator.contains,
			args: [],
		});
	};
}

export function ValidationEmail(message?: string, options?: validator.IsEmailOptions) {
	return function (target: any, key: string) {
		addValidationToTarget(target, {
			target: key,
			message: message || `${getDisplayName(target, key)} is not a valid email.`,
			callback: validator.isEmail,
			args: [
				{
					...options,
				} as validator.IsEmailOptions,
			],
		});
	};
}

export function ValidationSameAs(otherField: string, message?: string) {
	return function (target: any, key: string) {
		addValidationToTarget(target, {
			target: key,
			message: message || `${getDisplayName(target, key)} is not the same as ${otherField}`,
			callback: function () {
				return validator.equals((this as any)[key] || '', (this as any)[otherField] || '');
			},
			args: [],
		});
	};
}

export function ValidationLength(min?: number, max?: number, message?: string, options?: validator.IsLengthOptions) {
	const getMessage = (target: any, key: string): string => {
		let msg = `${getDisplayName(target, key)} should be `;

		if (min !== undefined && max !== undefined) {
			msg += `between ${min} and ${max}`;
		} else if (min !== undefined) {
			msg += `at least ${min}`;
		} else if (max !== undefined) {
			msg += `at most ${min}`;
		}
		msg += ` characters long.`;

		return msg;
	};
	return function (target: any, key: string) {
		addValidationToTarget(target, {
			target: key,
			message: message || getMessage(target, key),
			callback: validator.isLength,
			args: [
				{
					...options,
					min,
					max,
				} as validator.IsLengthOptions,
			],
		});
	};
}
