import React from 'react';

export enum BtnGroupAlignment {
	Horizontal = 'horizontal',
	Vertical = 'vertical',
}

export enum BtnGroupSizing {
	Static = 'static',
	Grow = 'groups',
}

interface IButtonGroupProps {
	alignment: BtnGroupAlignment;
	sizing: BtnGroupSizing;
}

const defaultProps: IButtonGroupProps = {
	alignment: BtnGroupAlignment.Horizontal,
	sizing: BtnGroupSizing.Static,
};

const getClassName = (props: IButtonGroupProps): string => {
	const classes: string[] = [
		'btn-group',
		`btn-group--${props.alignment}`,
		`btn-group--${props.sizing}-elements`,
		`btn-group--${props.alignment}`,
	];

	return classes.join(' ');
};

const ButtonGroup = (providedProps: React.PropsWithChildren<Partial<IButtonGroupProps>>) => {
	const props = { ...defaultProps, ...providedProps };

	return <section className={getClassName(props)}>{props.children}</section>;
};

export default ButtonGroup;
