import Axios, { AxiosResponse } from 'axios';
import LoginResponse from '../model/response/LoginResponse';
import Config from '../Config';
import srs from 'secure-random-string';
import SignupRequest from '../model/request/SignupRequest';
import ForgotStartRequest from '../model/request/ForgotStartRequest';
import ForgotCompleteRequest from '../model/request/ForgotCompleteRequest';
import LoginRequest from '../model/request/LoginRequest';
import OauthClientResponse from '../model/response/OauthClientResponse';
import ExceptionResponse from '../model/response/ExceptionResponse';
import ConfirmEmailRequest from '../model/request/ConfirmEmailRequest';

export class AuthServiceHttpError extends Error {
	public response: AxiosResponse<ExceptionResponse>;
	public originalError: Error;

	constructor(response: AxiosResponse<ExceptionResponse>, originalError: Error) {
		super(originalError.message);
		this.originalError = originalError;
		this.name = originalError.name;
		this.stack = originalError.stack;
		this.response = response;
	}

	public getErrorMessage(): string {
		return this.response.data.title || 'Something unexpected happened, please contact support.';
	}
}

Axios.interceptors.response.use(
	function (response) {
		// Status code 2xx
		return response;
	},
	function (error) {
		// Anything else
		if (error.response) {
			return Promise.reject(new AuthServiceHttpError(error.response, error));
		}
		return Promise.reject(error);
	},
);

export default class AuthService {
	public static GenerateSecurePassword(length = 16) {
		return srs({ alphanumeric: true, length });
	}

	public static Logout() {
		window.location.href = Config.LogoutUri;
	}

	public static async Login(loginRequest: LoginRequest): Promise<boolean> {
		try {
			const response = await Axios.post<LoginResponse>(`/auth/login`, loginRequest);

			if (response.data.RedirectUri) {
				window.location.href = response.data.RedirectUri;
			}

			return true;
		} catch (err) {
			// Rethrow the error if it is not a 404
			if (err.response.status !== 401) {
				throw err;
			}
		}

		return false;
	}

	public static async Signup(signupRequest: SignupRequest, redirectTo?: string, refCode?: string) {
		await Axios.post<LoginResponse>(`/auth/signup`, signupRequest);

		const params = [];
		if (redirectTo) {
			params.push(`redirect=${encodeURIComponent(redirectTo)}`);
		}
		if (refCode) {
			params.push(`r=${encodeURIComponent(refCode)}`);
		}

		window.location.href = Config.PlatformLoginUri + (params.length ? `?${params.join('&')}` : '');
	}

	public static async RequestResetPassword(resetRequest: ForgotStartRequest) {
		await Axios.post(`/auth/reset/request`, resetRequest);
	}

	public static async CompleteResetPassword(completeRequest: ForgotCompleteRequest) {
		await Axios.post(`/auth/reset/complete`, completeRequest);
	}

	public static async GetOauthClientFromLoginChallenge(challenge: string): Promise<OauthClientResponse> {
		const response = await Axios.get<OauthClientResponse>(`/auth/oauthClientFromLoginChallenge/${challenge}`);
		return response.data;
	}

	public static async ConfirmEmailAddress(request: ConfirmEmailRequest): Promise<boolean> {
		try {
			await Axios.post(`/auth/email/confirm`, request);
			return true;
		} catch (err) {
			// Rethrow the error if it is not a 404
			if (err.response.status === 404) {
				return false;
			}

			throw err;
		}
	}
}
