import React, { createContext, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from './component/Login';
import Forgot from './component/Forgot';
import Home from './component/Home';
import Signup from './component/Signup';
import NotFound from './component/NotFound';
import ConfirmEmail from './component/ConfirmEmail';
import Error from './component/Error';
import Alert from './component/element/Alert';
import Spinner from './component/element/Spinner';
import UserService from './service/UserService';
import AnalyticsService from './service/AnalyticsService';
import { globalStore } from './GlobalStore';
import { observer } from 'mobx-react';
import AlertUtil from './util/AlertUtil';
import EnvironmentService from './service/EnvironmentService';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '@cb/solaris-react/constants/Theme';
import logo from './static/img/logo.svg';

export const analytics = new AnalyticsService();
export const AnalyticsContext = createContext(analytics);

UserService.GetUserInfo().then((user) => {
	globalStore.setUser(user);
});

EnvironmentService.getEnvironmentInformation().then((env) => {
	globalStore.setEnvironment(env);
	analytics.setAnalyticsServerRootUri(env.AnalyticsUri);
});

const App = observer(() => {
	const [theme] = useState(defaultTheme);
	return (
		<>
			<Spinner isVisible={globalStore.isSpinnerVisible}></Spinner>
			<Alert
				message={globalStore.alertMessage}
				isVisible={globalStore.alertMessage ? true : false}
				displayType={globalStore.alertMessageDisplayType}
				type={globalStore.alertMessageType}
				onDismissed={() => AlertUtil.HideAlert()}
			/>
			<ThemeProvider theme={theme}>
				<div className="content absolute-center">
					<Router>
						<Switch>
							<Route path="/login" component={Login} />
							<Route path="/forgot" component={Forgot} />
							<Route path="/signup" component={Signup} />
							<Route path="/error" component={Error} />
							<Route path="/confirm" component={ConfirmEmail} />
							<Route exact path="/" component={Home} />
							<Route component={NotFound} />
						</Switch>
					</Router>
					<footer>
						<img src={logo} alt="Codebots" />
						<p className="txt-sm-body">
							<a href="https://codebots.com/privacy" target="_blank" rel="noreferrer">
								Privacy Policy
							</a>{' '}
							|{' '}
							<a href="https://codebots.com/full-software-licence" target="_blank" rel="noreferrer">
								Software Licence
							</a>
						</p>
						<p className="txt-sm-body">Copyright &copy; {new Date().getFullYear()} Codebots Pty Ltd</p>
					</footer>
				</div>
			</ThemeProvider>
		</>
	);
});

export default App;
